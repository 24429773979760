
import { defineComponent } from "vue";
import AdminCatalogSection from "@/components/sections/admin/AdminCatalogSection.vue";
import AdminLayout from "@/templates/AdminLayout.vue";

export default defineComponent({
  name: "AdminCatalog",
  components: {
    AdminCatalogSection,
    AdminLayout,
  },
  mounted() {
    const token = localStorage.getItem('user');
    if(JSON.parse(localStorage.getItem('user')) == "user-token") {
      localStorage.removeItem('user');
      this.$router.push('/admin')
    }
  }
});
