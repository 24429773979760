
import { defineComponent } from "vue";
import Filter from "@/components/molecules/Filter.vue";
import MainCard from "@/components/molecules/MainCard.vue";

export default defineComponent({
  name: "AdminCatalogSection",
  data() {
    return {
      data: {},
      dataBodyCheckbox: [
        {'Rarity': ['Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Legendary']}
      ]
    };
  },
  components: {
    Filter,
    MainCard,
  },
  mounted() {
    this.getAdminCatalog()
  },
  methods: {
    getAdminCatalog() {
      this.$store.dispatch("getNFTs/getAdminCatalog").then(
        (response) => {
          this.data = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
});
